<template>
  <div class="emoji-panel-wrap">
    <emoji v-for="(item,index) in emojis" :text="item" v-bind:key="index" @onClick="handleEmojiClick"></emoji>
    <div class="btn" @click="close">关闭</div>
  </div>
</template>
<script>
  import Emoji from "./Emoji.vue";
  export default {
    data() {
      return {
        emojis: [
          "angry",
          "anguished",
          "astonished",
          "blush",
          "cold_sweat",
          "confounded",
          "confused",
          "cry",
          "disappointed",
          "disappointed_relieved",
          "dizzy_face",
          "expressionless",
          "fearful",
          "flushed",
          "frowning",
          "grimacing",
          "grin",
          "grinning",
          "heart_eyes",
          "hushed",
          "innocent",
          "joy",
          "kissing_closed_eyes",
          "kissing_heart",
          "laughing",
          "neutral_face",
          "no_mouth",
          "open_mouth",
          "pensive",
          "persevere",
          "relaxed",
          "relieved",
          "sleepy",
          "smile",
          "smiley",
          "smirk",
          "sob",
          "stuck_out_tongue",
          "sunglasses",
          "sweat",
          "sweat_smile",
          "scream",
          "wink",
          "unamused",
          "satisfied",
          "worried",
          "stuck_out_tongue_closed_eyes",
          "weary",
          "yum",
          "tired_face",
          "triumph",
          "stuck_out_tongue_winking_eye"
        ]
      };
    },
    components: {
      Emoji
    },
    methods: {
      handleEmojiClick(a) {
        this.$emit("emojiClick", a);
      },
      close() {
        //收回表情区域
        this.$emit("emojiCloseClick", '');
      }
    }
  };
</script>
<style scoped>
  .emoji-panel-wrap {
    box-sizing: border-box;
    height: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    position: absolute;
    left: 0px;
    top: -32px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 52px;
  }

  .btn {
    margin-right: 10px;
    text-align: right;
    font-size: 30px;
  }
  
</style>

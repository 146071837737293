<template>
  <div class="vipMain">
    <div class="backHome" v-if="toolsShow == true">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      社交网络
    </div>
    <div class="wechat">
      <div class="flex flex__justify flex__middle">
        <div class="flex flex__justify mt-10 ml-15 mr-15" @click="viewDetails(userCode)">
          <van-image round width="4rem" height="4rem" :src="imgUrl" />
          <div class="cblue ml-10 mt-10">{{userName == '' ? '匿名用户' : userName }}</div>
        </div>

        <!-- 删除聊天记录 -->
        <!--    		<img class="icon28" src="../../assets/img/delmsg.png"  @click="delMsg(userCode)"/> -->


        <img class="icon28" src="../../assets/img/iconfonti.png" @click="viewDetails(userCode)" />
      </div>

    </div>

    <div class="center_box" id="msgList">
      <!--      <div class="center mt-10 f12">今天11.30</div>
      <div class="item right">
        <div class="messagel">今天天气真好</div>
        <div class="onel"></div>
      </div>
      <div class="item left">
        <div class="oner"></div>
        <div class="messager">
          今天天气真好
        </div>
      </div> -->
    </div>

    <div class="bottom">
      <div class="flex flex__justify flex__middle">
        <van-field v-model="message" rows="1" id="textpanel" type="textarea" placeholder="请输入您想要说的话"
          class="comment-input" @keyup.enter.native="sendMsg()" />
        <!--       <div class="content mr-15 ml-15" style="margin-right:10px;width:50px;" @click="sendMsg()">发送</div> -->
        <img class="icon22 mr-15" style="margin-right:10px" src="../../assets/img/fasong.png" @click="sendMsg()" />
        <!-- 发送    -->
      </div>
      <div class="mt-10" style="margin: 0 3vw;">
        <img class="icon22 ml-15" src="../../assets/img/liwu2.png" style="padding:5px 10px;display:none;"
          @click="gift()" />
        <!-- @click="handleLiwu()" -->
        <!-- 礼物 -->


        <!-- 发语音 -->
        <img class="icon22 ml-15" v-if="flag" style="padding:5px 10px" @click="sendVoiceMessage()"
          src="../../assets/img/jiaohuan.png" />

        <!--        <img class="icon22 ml-15" v-if="flag" style="padding:5px 3px" @click="testvoice()"
            src="../../assets/img/jiaohuan.png" /> -->


        <!-- 表情 -->
        <img class="icon22 ml-15 emoji-panel-btn" style="padding:5px 10px" @click="showEmojiPanel()"
          src="../../assets/img/smile.png" />

        <!-- 传图 -->
        <img class="icon22 ml-15" style="padding:5px 7px" @click.stop="uploadHeadImg"
          src="../../assets/img/tupian.png" />

        <!--   @click="handleXiangji()" -->
      </div>
      <!-- 表情 -->
      <div class="smile1 ml-15 mr-15" v-if="smile === true">
        <emoji-panel @emojiClick="appendEmoji" @emojiCloseClick="close_emoji"></emoji-panel>
      </div>
      <!-- 礼物 -->
      <div class="liwu1" v-if="liwu === true">
        <div class="content mr-15 ml-15">礼物</div>
      </div>
    </div>

    <div class="xiangji mt-10 ml-15 mr-15">
      <!--   <van-action-sheet v-model="xiangji" title=" "> -->
      <!-- <div class="content mt-10">相机文件</div> -->
      <input type="file" accept="image/*" @change="handleFile" class="hiddenInput" style="display: none" />
      <!--    </van-action-sheet> -->
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast,
    Dialog
  } from "vant";
  import $ from "jquery";
  import EmojiPanel from "./children/EmojiPanel.vue";
  import wx from 'weixin-js-sdk';
  export default {
    name: "",
    props: {},
    data() {
      return {
        soundIdMap: {}, //保存微信声音id的映射, 服务器id-->本地id, 避免重复下载
        currentPlayingSoundLocalId: '', //正在播放的录音的本地id
        kh: '',
        userCode: '',
        userName: "",
        userHeadImg: "",
        message: "",
        lastMsgId: "", //接口返回的最后一条消息的id，下次询问数据的时候，这个传值进去
        toolsShow: true, //显示工具栏
        liwu: false,
        xiangji: false,
        flag: true,
        smile: false,
        comments: [],
        emoji1: "<span data-v-48c088f1='' style='background: url(/img/emoji_sprite.5622a07b.png);background-size: 153.6vw 102.4vw;display: inline-block;' class='emoji-item-common emoji-",
        emoji2: " emoji-size-large'></span>",
        emojis: [
          "angry",
          "anguished",
          "astonished",
          "blush",
          "cold_sweat",
          "confounded",
          "confused",
          "cry",
          "disappointed",
          "disappointed_relieved",
          "dizzy_face",
          "expressionless",
          "fearful",
          "flushed",
          "frowning",
          "grimacing",
          "grin",
          "grinning",
          "heart_eyes",
          "hushed",
          "innocent",
          "joy",
          "kissing_closed_eyes",
          "kissing_heart",
          "laughing",
          "neutral_face",
          "no_mouth",
          "open_mouth",
          "pensive",
          "persevere",
          "relaxed",
          "relieved",
          "sleepy",
          "smile",
          "smiley",
          "smirk",
          "sob",
          "stuck_out_tongue",
          "sunglasses",
          "sweat",
          "sweat_smile",
          "scream",
          "wink",
          "unamused",
          "satisfied",
          "worried",
          "stuck_out_tongue_closed_eyes",
          "weary",
          "yum",
          "tired_face",
          "triumph",
          "stuck_out_tongue_winking_eye"
        ],
        refreshTimer: null,
        languageLib: {
          lang_1: {
            title: 'chating',
            today: 'Today',
            chatingFreindsEmpty: 'Your conversation freinds is empty. ',
            icon: 'icon',
            uneffiencyAmount: 'amount insufficient, continue chating by <span>upgrade</span> or <span>buy coin </span>',
            sendPlaceholder: 'message',
            send: 'Send',
            coin: 'Coin',
            giveGift: 'Give gifts',
            noWords: 'you have not said anything yet. ',
            delMsg: 'del message',
            removeMsg: {
              message: 'confirm del message? ',
              yesButton: 'Yes',
              noButton: 'No',
            }
          },
          lang_2: {
            title: '聊天',
            today: '今天',
            chatingFreindsEmtpy: '您的会话列表为空',
            icon: '图标',
            uneffiencyAmount: '余额不足, 请到<span>升级</span>继续聊天对话框',
            sendPlaceholder: '短信',
            send: '发送',
            coin: '金币',
            giveGift: '发送礼物',
            noWords: '您还没有聊天记录',
            delMsg: '删除聊天记录',
            removeMsg: {
              message: '确认删除聊天记录?',
              yesButton: '确认',
              noButton: '取消',
            }
          },
          lang_3: {
            title: '聊天',
            today: '今日',
            chatingFreindsEmtpy: '您的談話列表為空',
            icon: '圖標',
            uneffiencyAmount: '餘額不足, 請到<span>升級</span>繼續聊天對話框',
            sendPlaceholder: '短信',
            send: '發送',
            coin: '金幣',
            giveGift: '發送禮物',
            noWords: '沒有談話紀錄',
            delMsg: '刪除談話紀錄',
            removeMsg: {
              message: '確認刪除談話?',
              yesButton: '確認',
              noButton: '取消',
            }
          }
        },
        currentLang: null
      };
    },
    components: {
      EmojiPanel,
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2'];
    },
    created() {
      //onclick事件调用vue的函数
      //onclick 执行的是 window 环境中的方法，所以：将 this 中的方法关联到 window 上即可。
      window.playWxSound = this.playWxSound
    },
    mounted() {
      document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
        // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
        WeixinJSBridge.call('hideOptionMenu');
      });
      //加载用户的头像和名字
      var url = location.href;
      //decodeURI($("#userForm").serialize())
      this.userCode = decodeURI(getQueryVariable(url, "userCode"));
      this.deviceType = getQueryVariable(url,
        "deviceType");
      if (this.deviceType == "pc") {
        //如果是pc，那么就是电脑调用，不显示对应的顶部导航
        this.toolsShow = false;
      }
      this.getWxConfig(); //加载js
      //加载这个会员的详情
      this.getUserInfo(this.userCode);
      //加载最新的50条聊天记录
      this.getMsgList(this.userCode); //拉取历史聊天记录，返回最后一条消息的id

      var div = document.getElementById("msgList");
      div.scrollTop = Number(div.scrollHeight) + 9000; //

    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(uri);
        console.log(param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              // 'updateTimelineShareData',
              // 'updateAppMessageShareData',
              'startRecord',
              'stopRecord',
              'onVoiceRecordEnd',
              'playVoice',
              'pauseVoice',
              'stopVoice',
              'onVoicePlayEnd',
              'uploadVoice',
              'downloadVoice',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'hideOptionMenu'
            ]
          });

          wx.ready(function() {
            //console.log("js注册成功");
            wx.hideOptionMenu();
            ////Toast("微信js注册成功");
            // var shareData = {
            //   title: '拼团开始了',
            //   desc: '开始拼团活动啦，你也快来参与吧！',
            //   link: openurl,
            //   imgUrl: imgurl
            // };
            // wx.updateTimelineShareData(shareData); //分享到朋友圈
            // wx.updateAppMessageShareData(shareData); //分享给好友
          });

        } else {
          Toast(result.msg);
        }
      },
      testvoice() {
        const that = this;
        const serverId = "IgYQk345y5ARMr9AmHDqQvMFcZalRq_mKbRN8pKvjHptM5uVZ5fhGVAq6SYDfEcS"; // 返回音频的服务器端ID
        console.log('录音上传完毕, 录音服务器id: ' + serverId);
        let uri = that.$global.domain + "/tools/post_2.ashx?action=bSendMsg";
        let content = '💬  16"'; //最好能获取时长,传值这里  ((()))
        this.message = ""; //文本框立刻清空，给用户感觉火速发出来了
        let param = {
          action: "bSendMsg",
          msgType: "5", //5是聊天  2是站内信
          pname: this.userCode, //收信人
          sname: Cookies.get("kh"), //发信人
          ntitle: "语音信息", //标题
          ncontent: content, //最好能获取时长,传值这里
          voiceId: serverId, //这里存语音信息id
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        RequestApi.post(uri, param).then((res) => {
          console.log("语音消息发送完成, 接口响应: ", res);
          const result = res;
          if (result.status == "1") {
            Toast('语音消息发送完成');
            $("#msgList").append(
              `<div class=" item right" > <div class="messagel"  onclick="playWxSound('${serverId}');" >${content}</div><div class="onel"></div></div>)`
            );
            var div = document.getElementById("msgList");
            div.scrollTop = Number(div.scrollHeight) + 900; //
          } else {
            Toast(result.msg);
          }
        }).catch((e) => {
          console.log('发送消息失败: ', e);
        });
      },
      sendWxMessage(localId, seconds) {
        const that = this;
        //上传录音并发送消息
        wx.uploadVoice({
          localId: localId, // 需要上传的音频的本地ID，由stopRecord接口获得
          isShowProgressTips: 1, // 默认为1，显示进度提示
          success: function(res) {
            const serverId = res.serverId; // 返回音频的服务器端ID
            console.log('录音上传完毕, 录音服务器id: ' + serverId);
            let uri = that.$global.domain + "/tools/post_2.ashx?action=bSendMsg";
            let content = '(((' + seconds + '"'; //获取时长,传值这里
            that.message = ""; //文本框立刻清空，给用户感觉火速发出来了
            let param = {
              action: "bSendMsg",
              msgType: "5", //5是聊天  2是站内信
              pname: that.userCode, //收信人
              sname: Cookies.get("kh"), //发信人
              ntitle: "语音信息", //标题
              ncontent: content, //获取时长,传值这里
              voiceId: serverId, //这里存语音信息id
              sitenum: "18777777777",
              token: Cookies.get("token"),
              code: Cookies.get("code"),
            };
            RequestApi.post(uri, param).then((res) => {
              console.log("语音消息发送完成, 接口响应: ", res);
              const result = res;
              if (result.status == "1") {
                Toast('语音消息发送完成');
                $("#msgList").append(
                  `<div class=" item right" > <div class="messagel" onclick="playWxSound('${serverId}');" >${content}</div><div class="onel"></div></div>`
                );
                var div = document.getElementById("msgList");
                div.scrollTop = Number(div.scrollHeight) + 900; //
              } else {
                Toast(result.msg);
              }
            }).catch((e) => {
              console.log('发送消息失败: ', e);
            });
          }
        });
      },
      //发送语音id到服务器
      sendVoiceMessage() {
        let that = this;
        if (this.userCode == "") {
          Toast("请选择您要发信息的用户");
          return;
        }
        wx.startRecord();
        const recordStartTime = new Date().getTime();
        console.log('录音开始, 开始时间: ', recordStartTime);
        Dialog.confirm({
          message: '声音录制中, 最多一分钟',
          confirmButtonText: '发送',
          cancelButtonText: '取消',
        }).then(() => {
          console.log('录音结束');
          const recordEndTime = new Date().getTime();
          const seconds = parseInt((recordEndTime - recordStartTime) / 1000);
          wx.stopRecord({
            success: function(res) {
              var localId = res.localId;
              console.log('结束录制, 本地ID = ' + localId);
              console.log('准备上传');
              that.sendWxMessage(localId, seconds);
            }
          });
        }).catch(() => {
          console.log('取消录制');
          wx.stopRecord({
            success: function(res) {
              var localId = res.localId;
              console.log('取消录制, 本地ID = ' + localId + ' ; 不上传');
            }
          });
        });
        wx.onVoiceRecordEnd({
          complete: function(res) {
            var localId = res.localId;
            const recordEndTime = new Date().getTime();
            console.log('录音超时1min, 结束录制, 本地ID = ' + localId + ' , 实际耗时: ' + (recordEndTime - recordStartTime));
            Dialog.close();
            Dialog.confirm({
              message: '录音超过1min, 已结束录制, 是否发送',
              confirmButtonText: '发送',
              cancelButtonText: '取消',
            }).then(() => {
              console.log('需要发送录音, 准备上传');
              that.sendWxMessage(localId, 60);
            }).catch(() => {
              console.log('取消发送');
            })
          }
        })
      },
      viewDetails(userCode) {
        this.$router.push({
          path: '/userDet',
          query: {
            'userCode': userCode
          }
        });
      },
      showEmojiPanel() {
        //打开表情框
        this.smile = true;
        this.xiangji = false;
        this.liwu = false;
      },
      appendEmoji(text) {
        //表情点击后
        const el = document.getElementById("textpanel");
        this.message = el.value + ":" + text + ":";
        //收回表情区域
        this.smile = false;
        this.xiangji = false;
        this.liwu = false;
      },
      close_emoji() {
        //收回表情区域
        this.smile = false;
        this.xiangji = false;
        this.liwu = false;
      },
      handleLiwu() {
        //礼物
        this.liwu = true;
        this.smile = false;
        this.xiangji = false;
      },
      gift(item) {
        //给这个会员送礼物
        this.$router.push({
          path: '/gift',
          query: {
            'type': 'sendgifts', //送礼状态下，不显示会员自己收到的礼物
            'userCode': this.userCode
          }
        });
      },
      handleXiangji() {
        //相机区域
        this.xiangji = true;
        this.liwu = false;
        this.smile = false;
      },
      handleShow() {
        this.smile = true;
      },
      back(where) {
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prev") {
          this.$router.back()
        }
      },
      async getUserInfo(userCode) {
        //获取会员部分信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getLikeUserInfo2021",
          user: Cookies.get("kh"),
          queryUser: userCode, //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.userName = result.name;
          that.userHeadImg = result.avatar;
          that.userName = result.name
          if (result.sex == '保密') {
            Cookies.set("sex", "男");
            //没有设置性别,就必须先设置了才允许
            // Toast("请您先设置性别后,再进行访问");
            // //延时跳转 让顾客看清提示
            // setTimeout(() => {
            //   //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            //   this.$router.push("setting");
            //   //延迟时间：3秒
            // }, 500)
          } else {
            Cookies.set("sex", result.sex);
          }
        } else {
          //报错一般都是token
          //Toast(result.msg);
        }
      },
      async sendMsg() {
        //发信息  用户发文本消息
        if (this.userCode == "") {
          Toast("请选择您要发信息的用户");
          return;
        }
        if (this.message == "") {
          Toast("请填写内容");
          return;
        }
        var that = this;
        var uri = "";
        var content = this.message;
        this.message = ""; //文本框立刻清空，给用户感觉火速发出来了
        uri = this.$global.domain + "/tools/post_2.ashx?action=bSendMsg";
        var param = {
          action: "bSendMsg",
          msgType: "5", //5是聊天  2是站内信
          pname: this.userCode, //收信人
          sname: Cookies.get("kh"), //发信人
          ntitle: "一对一聊天", //标题
          ncontent: content, //正文
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log("================>接口返回");
        console.log(result);
        if (result.status == "1") {
          //是不是有好一点的写法

          //将所有的表情符号读出来，替换为图片
          for (var i = 0; i < this.emojis.length; i++) {
            content = content.replace(":" + this.emojis[i] + ":", this.emoji1 + this.emojis[i] + this.emoji2);
          }
          //加入界面，否则用户觉得卡 ---》废弃 ---》//不要把内容加入界面，直接获取最新数据，否则会有重复发出信息
          //内容加入界面
          $("#msgList").append(
            ` <div class=" item right"> <div class="messagel" >${content}</div><div class="onel"></div></div>`);


          //that.getNewMsg(that.userCode);

          let div = document.getElementById("msgList");
          div.scrollTop = Number(div.scrollHeight) + 36 + 80; // 移动到最底部 这样用户知道信息发出来了

        } else {
          Toast(result.msg);
        }
      },
      // 打开图片上传
      uploadHeadImg: function() {
        this.$el.querySelector(".hiddenInput").click();
      },
      // 将照片显示出来
      handleFile: function(e) {
        let $target = e.target || e.srcElement;
        let file = $target.files[0];
        var reader = new FileReader();
        reader.onload = (data) => {
          let res = data.target || data.srcElement;
          this.headimg = res.result;
        };
        const isImg = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isImg) {
          Toast("上传图片只能是 JPG/JPEG/PNG 格式!");
          return false;
        }
        if (!isLt2M) {
          Toast("上传图片大小不能超过 4MB! 当前文件尺寸:" + file.size);
          return false;
        }

        reader.readAsDataURL(file);
        //直接调用上传接口，将图片传给后台
        //要考虑文件加载时间,先做了一个延时
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.uploadImg(file);
          //延迟时间：秒
        }, 500);
      },
      //通过接口传图片到服务器
      async uploadImg(file) {
        var _this = this;
        let file1 = this.headimg; //获取文件数据
        var arr = [];
        arr.push({
          imgurl: file1,
        });
        console.log(arr);
        var imglist = JSON.stringify(arr);
        var uri = "";
        uri = this.$global.domain + "/tools/mallsubmit.ashx";
        var param = {
          action: "saveImglist",
          user: Cookies.get("kh"),
          pname: this.userCode, //收信人
          type: 5, //1 上传照片  2换头像 5，聊天发图片，直接存入聊天  4，邮件发图片
          imglist: imglist,
          sitenum: "18777777777",
          token: Cookies.get("token"), //验证用户合法性
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //图片直接存入了聊天记录

          //拼接样式，图片发入聊天窗口
          $("#msgList").append('<div class=" item right"> <div class="messagel"><img src="' + result.data +
            '" width="200px"></img></div><div class="onel"></div></div>');

          let div = document.getElementById("msgList");
          div.scrollTop = Number(div.scrollHeight) + 36 + 80; // 移动到最底部 这样用户知道信息发出来了

        } else {
          Toast(result.msg);
        }
      },
      async getMsgList(searchUser) {
        //加载和这个人的聊天记录
        var that = this;
        var uri = this.$global.domain + "/tools/post_2.ashx?action=getUemails2021";
        var param = {
          action: "getUemails2021",
          msgType: "5", //5是聊天  2是站内信
          page: "1",
          user: Cookies.get("kh"),
          queryUser: searchUser,
          mId: "1", //1,全部，2已读
          limit: 100,
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log("================>接口返回");
        console.log(result);
        if (result.status == "1") {
          var data = result.data;
          if (data.length > 0) {
            var lastTime = "2021-01-01 00:00:01"; //获取当前时间; //最後一次消息的時間，用與判斷是否顯示時間
            for (var i = 0; i < data.length; i++) {
              if (data[i].content != '') {
                if (i == data.length - 1) {
                  this.lastMsgId = data[i].id; //如果是本次接口返回的最后一条信息，那么传值给变量，下一次查询，会只发给本id之后的数据
                }
                var sData = {
                  name: data[i].post_user_name,
                  type: data[i].is_read, //是否读了
                  conteng: data[i].content,
                  // img: data[i].headImg,
                };
                var content = data[i].content;
                let msgtype = data[i].title; //语音还是文字
                let orderno = data[i].orderno; //对应单据号,如果是语音,这里存的语音id
                //是不是有好一点的写法

                //将所有的表情符号读出来，替换为图片
                for (var j = 0; j < this.emojis.length; j++) {
                  content = content.replace(":" + this.emojis[j] + ":", this.emoji1 + this.emojis[j] + this.emoji2);
                }

                //将里面的表情符号，转为表情图标
                //将里面发的图片，转为图片
                //判断是谁发的，调用对应的样式
                //时间，只要超过10分钟，就显示一次
                // if (i == 0 || i == 5 || i == 10)
                //   $("#msgList").append(`<div class="center">${data[i].post_time}</div>`);
                //时间，只要超过10分钟，就显示一次
                var dateDiff = new Date(data[i].post_time).getTime() - new Date(lastTime)
                  .getTime(); //时间差的毫秒数
                console.log("=========》data[i].post_time：" + data[i].post_time)
                console.log("=========》lastTime：" + lastTime)
                console.log("=========》dateDiff：" + dateDiff)
                if (dateDiff >= 1000) {
                  $("#msgList").append(`<div class="center">${data[i].post_time}</div>`);
                }

                lastTime = new Date(data[i].post_time).getTime();

                if (data[i].post_user_name == Cookies.get("kh")) {
                  //当前会员发的
                  $("#msgList").append(
                    `<div class=" item right"> <div class="messagel" onclick="playWxSound('${orderno}');" >${content}</div><div class="onel"></div></div>`
                  );
                } else {
                  //对面的人发的
                  $("#msgList").append(
                    `<div class=" item left"><div class="messager"  onclick="playWxSound('${orderno}');" >${content}</div> <div class="oner"></div></div>`
                  );
                }
              }
            }
            this.toBottom(); //直接回到底部，否则新读出来的消息看不到
          }

        } else {
          //Toast(result.msg);
        }

        //直接回到底部，否则新读出来的消息看不到
        // $("#msgList")[0].scrollTop = $("#msgList")[0].scrollHeight + 90;

        //setTimeout(this.toBottom(), 800);  有没有消息都到底部，客户怎么看记录，有消息才滚动


        //console.log("================================》定时器开始执行了")
        //setTimeout(that.getNewMsg(that.userCode), 3000); //打开定时器，调用获取最新消息的接口，实时拉取最新消息
        // console.log('30秒后调用 getNewMsg: ' + new Date().getTime());
        // console.log(that);
        that.refreshTimer = setTimeout(() => {
          that.getNewMsg(that.userCode);
        }, 1000 * that.$global.refreshChatingMegIntervalSec);
      },
      toBottom() {
        //移动到底部
        var div = document.getElementById("msgList");
        div.scrollTop = Number(div.scrollHeight) + 39000; //
      },
      playWxSound(serverId) {
        if (serverId == 'null' || serverId == "" || serverId == "undefined") {
          return; //没有音频id直接跳出
        }
        const that = this;
        let localId = this.soundIdMap[serverId];
        if (!localId) {
          console.log('语音 ' + serverId + ' 没有下载, 先下载');
          wx.downloadVoice({
            serverId: serverId,
            isShowProgressTips: 1,
            success: (res) => {
              localId = res.localId;
              console.log('语音下载完成, 服务器id = ' + serverId + ' ; 本地id = ' + localId);
              that.soundIdMap[serverId] = localId;
              console.log('准备播放: ', that.soundIdMap);
              doPlay(localId);
            }
          });
        } else {
          doPlay(localId);
        }

        function doPlay(localId) {
          if (that.currentPlayingSoundLocalId) {
            //先停止正在播放的声音
            console.log('有声音正在播放中, 先停止: ' + that.currentPlayingSoundLocalId);
            wx.stopVoice({
              localId: that.currentPlayingSoundLocalId
            });
            that.currentPlayingSoundLocalId = '';
          }

          //播放
          wx.playVoice({
            localId: localId
          });
          that.currentPlayingSoundLocalId = localId;
          console.log('声音播放中, 本地Id = ' + localId);
          //播放完毕的回调
          wx.onVoicePlayEnd({
            success: function(res) {
              const localId = res.localId; // 返回音频的本地ID
              console.log('声音播放完毕, 本地id = ' + localId);
              that.currentPlayingSoundLocalId = '';
            }
          });
        }
      },
      async getNewMsg(searchUser) {
        // console.log('getNewMsg开始调用: ' + new Date().getTime());
        // console.log("================================》定时器开始执行了1")
        //加载和这个人的聊天记录
        var that = this;
        var uri = this.$global.domain + "/tools/post_2.ashx?action=getNewMsg2021";
        var param = {
          action: "getNewMsg2021",
          msgType: "5", //5是聊天  2是站内信
          page: "1",
          msgId: this.lastMsgId, //每次加载后会传值最后一个id，本次传值进去，服务器会将之后最新的消息发来 空就是全部获取
          user: Cookies.get("kh"),
          queryUser: searchUser,
          mId: "1", //1,全部，2已读
          limit: 100,
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        //console.log("================================》本次要查询的消息ID：" + this.lastMsgId)
        var result = await RequestApi.post(uri, param);

        // console.log("================>接口返回222222222222222222");
        // console.log(result);
        //只要获取到了新消息，直接就在服务器标记已读，不管会员看没看，开了页面肯定也看了
        if (result.status == "1") {
          var data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              if (data[i].content != '') {
                //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@==》" + data[i].id);
                if (i == data.length - 1) {
                  this.lastMsgId = data[i].id; //如果是本次接口返回的最后一条信息，那么传值给变量，下一次查询，会只发给本id之后的数据
                }
                var sData = {
                  name: data[i].post_user_name,
                  type: data[i].is_read, //是否读了
                  conteng: data[i].content,
                  // img: data[i].headImg,
                };
                var content = data[i].content;
                let msgtype = data[i].title; //语音还是文字
                let orderno = data[i].orderno; //对应单据号,如果是语音,这里存的语音id
                //是不是有好一点的写法

                //将所有的表情符号读出来，替换为图片
                for (var j = 0; j < this.emojis.length; j++) {
                  content = content.replace(":" + this.emojis[j] + ":", this.emoji1 + this.emojis[j] + this.emoji2);
                }

                //将里面的表情符号，转为表情图标
                //将里面发的图片，转为图片
                //判断是谁发的，调用对应的样式
                //时间，只要超过10分钟，就显示一次
                if (i == 0 || i == 5 || i == 10)
                  $("#msgList").append(`<div class="center">${data[i].post_time}</div>`);
                if (data[i].post_user_name == Cookies.get("kh")) {
                  //当前会员发的
                  $("#msgList").append(
                    `<div class=" item right"> <div class="messagel"  onclick="playWxSound('${orderno}');">${content}</div><div class="onel"></div></div>`
                  );
                } else {
                  //对面的人发的
                  $("#msgList").append(
                    `<div class=" item left"><div class="messager" onclick="playWxSound('${orderno}');">${content}</div> <div class="oner"></div></div>`
                  );
                }
              }
            }
          }
          //直接回到底部，否则新读出来的消息看不到
          this.toBottom(); //直接回到底部，否则新读出来的消息看不到
          //$("#msgList")[0].scrollTop = $("#msgList")[0].scrollHeight;
          // var div = document.getElementById("msgList");
          // div.scrollTop = Number(div.scrollHeight) + 900; //

        } else {
          //Toast(result.msg);
          // var div = document.getElementById("msgList");
          // div.scrollTop = Number(div.scrollHeight) + 900; //

        }

        //console.log('30秒后调用 getNewMsg: ' + new Date().getTime());
        that.refreshTimer = setTimeout(() => {
          this.getNewMsg(searchUser);
        }, 1000 * that.$global.refreshChatingMegIntervalSec);

        //不管消息是否获取到，定时器继续运行
        console.log("================================》定时器开始执行了3")
        //setTimeout( that.getNewMsg(that.userCode), 10000);  //打开定时器，调用获取最新消息的接口，实时拉取最新消息
      },
      delMsg(userCode) {
        //删除对方所有聊天记录
        this.$confirm({
          message: this.currentLang.removeMsg.message,
          button: {
            no: this.currentLang.removeMsg.noButton,
            yes: this.currentLang.removeMsg.yesButton
          },
          callback: confirm => {
            if (confirm) {
              let that = this;
              let uri = this.$global.domain + "/tools/post_2.ashx?action=uDeleAllMsg";
              let param = {
                action: "uDeleAllMsg",
                me: Cookies.get("kh"),
                she: userCode,
                sitenum: '18777777777',
                token: Cookies.get("token"), //验证用户合法性
              };

              RequestApi.post(uri, param)
                .then(result => {
                  if (result.status == "1") {
                    console.log(result.msg);
                    Toast(result.msg);
                    this.$router.back(); //直接后退，这样等于重新刷新了聊天列表

                  } else {
                    Toast(result.msg);
                  }
                });


            }
          }
        })

      },
      // end
    },
    computed: {
      //给头像传值
      imgUrl: function() {
        return this.userHeadImg;
      },
    },
    watch: {},
    beforeDestroy() {
      console.log(this);
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer); //定时器需要在页面销毁的时候清除掉，不然会一直存在！！！
      }
      console.log('定时器已经销毁');
    }
  };
</script>

<style lang="less">
  .smile1 {
    // width: 375px;
    // height: 200px;
    transform: scale(0.5);
    transform-origin: 0 0;
    width: 750px;
    height: 200px;


    .emoji-item-common {
      background: url("../../assets/img/emoji_sprite.png");
      background-size: 576px 384px;
      //background-size: 260px 173px;
      //background-size: 288px 192px;
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }

    .emoji-size-small {
      // 表情大小
      //zoom: 0.3;
    }

    .emoji-size-large {
      // zoom: 0.34; // emojipanel表情大小
      // margin: 2px;
    }
  }

  .liwu1 {
    width: 100%;
    height: 200px;
  }

  .bottom {
    width: 100%;
    // height: 80px;
    // line-height:200px;
    border-top: 1px solid #efefef;
    position: fixed;
    bottom: 0;
    background: #fff;
  }

  .comment-wrap {
    width: 375px;
    margin-bottom: 180px;

    .emoji-item-common {
      background: url("../../assets/img/emoji_sprite.png");
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }

    .comments-list {
      margin-top: 20px;

      .comments-list-item {
        margin-bottom: 20px;

        .comments-list-item-heading {
          display: inline-block;

          img {
            height: 22px;
            width: 22px;
            border-radius: 50%;
            vertical-align: middle;
          }

          .comments-list-item-username {
            margin-left: 5px;
            font-weight: bold;
          }
        }

        .comments-list-item-content {
          margin: 10px 0px;
          border-bottom: 1px solid #cccccc;

          &:last-child {
            border-bottom: 0;
          }

          span {
            vertical-align: top;
          }
        }
      }
    }

    .comment-input {
      height: 100px;
      width: 500px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 10px;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    .opration {
      width: 522px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .emoji-panel-btn {
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        img {
          height: 24px;
          width: 24px;
        }
      }

      .comment-send-btn {
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #1da1f2;
        border-radius: 100px;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 13px;
        color: #ffffff;
        background-color: #4ab3f4;

        &:hover {
          cursor: pointer;
        }
      }

      .comment-send-btn-bounce {
        position: relative;
      }

      .comment-send-btn-bounce:focus {
        outline: none;
      }

      .comment-send-btn-bounce:after {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 100px;
        border: 0px solid #1da1f2;
        opacity: 0.7;
        transition: all 0.1s;
      }

      .comment-send-btn-bounce:active:after {
        //.bounce active时 伪元素:after的样式
        opacity: 1;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border-radius: 100px;
        border: 2px solid #1da1f2;
        transition: all 0.2s;
      }
    }
  }

  @import "../../assets/css/emoji.css"; // 导入精灵图样式

  .liwu {
    .van-popup--bottom.van-popup--round {
      height: 200px;
      line-height: 200px;
      overflow-y: auto;
    }
  }

  .xiangji {
    .van-popup--bottom.van-popup--round {
      height: 100px;
    }
  }

  .wechat {
    width: 100%;
    height: 78px;
    background: #fff;
    border-bottom: 1px solid #efefef;
  }

  .center_box {
    width: 100%;
    // height: 100vh;
    height: 55vh;
    background: #f7f8fc;
    overflow-y: auto;
    padding-bottom: 90px;

    .item {
      display: flex;
      margin-bottom: 10px;
    }

    .left {
      position: relative;
      flex-direction: row;
    }

    .right {
      flex-direction: row-reverse;
      position: relative;
    }

    .messagel {
      max-width: 80%;
      min-width: 30px;
      min-height: 20px;
      // float: right;
      background: #bfe4f7;
      padding: 10px;
      margin-top: 10px;
      position: relative;
      right: 36px;
      border-radius: 4px;
      white-space: normal;
      word-break: break-all;
    }

    .onel {
      width: 0px;
      height: 0px;
      border-top: 10px solid transparent;
      border-left: 10px solid #bfe4f7;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      right: 18px;
      top: 16px;
    }

    .messager {
      min-width: 30px;
      min-height: 20px;
      max-width: 80%;
      // float: left;
      background: #fff;
      padding: 10px;
      // margin-top: 58px;
      position: relative;
      left: 36px;
      border-radius: 4px;
    }

    .oner {
      width: 0px;
      height: 0px;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid #fff;
      border-bottom: 10px solid transparent;
      position: absolute;
      left: 18px;
      top: 8px;
    }
  }

  .vipMain {
    min-height: 300px;
    // padding-bottom: 120px;
    padding-top: 40px;
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
